

@font-face {
    font-family: 'SpaceColony';
    src: url('/public/fonts/SpaceColony.woff2') format('woff2'),
         url('/public/fonts/SpaceColony.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
body {
    font-family: 'SpaceColony', sans-serif;
}
*{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}
body{
    overflow-x: hidden;
    background: white;
    font-family: 'SpaceColony', sans-serif;
}
.navBar {
    text-center: center;
    background-color: black;
    color: white;
    padding: 2px;
    font-family: 'SpaceColony', sans-serif; /* Using the font with a fallback */
}  
.col-md-6 span{
    font-size: 25px;
     
}
.joinBox {
    font-size: 25px;
}
.main-img{
    padding-left: 10%
}
.main-img img{
  height: 50vh;
    width: 93%;
}
.img1 img {
    height: 45vh;
    width: 100%;
  
    border:2px solid black ;
  ;
}
.img2 img {
    height: 45vh;
    width: 100%;
    border: 2px solid black;
}
.btn-custom-black {
    background-color: #000; /* Black background */
    color: #fff; /* White text */
    border: none; /* Removes the border */
    padding: 10px 20px; /* Custom padding */
    text-decoration: none; /* Removes underline from links */
    display: inline-block; /* Aligns text to center */
    font-size: 16px; /* Custom font size */
    margin: 4px 2px; /* Custom margin */
    cursor: pointer; /* Changes mouse cursor to pointer */
    border-radius: 5px; /* Optional: rounds corners */
  }
  

/* image icon  */
.col-md-12 img{
    padding: 5px;
}